export const APPLICATIONS_ROUTES = {
  root: '/applications',
  applications: () => `${APPLICATIONS_ROUTES.root}`,
  application: (applicationId: string) => `${APPLICATIONS_ROUTES.applications()}/${applicationId}`,

  applicationSettings: (applicationId: string) =>
    `${APPLICATIONS_ROUTES.applications()}/${applicationId}?view=settings`,

  applicationControlsPosture: (applicationId: string) =>
    `${APPLICATIONS_ROUTES.application(applicationId)}/controls-posture`,

  applicationDesignPosture: (applicationId: string) =>
    `${APPLICATIONS_ROUTES.application(applicationId)}/design-posture`,

  // TODO below query params usage should be handled in a different way
  applicationReviewsCandidates: (applicationId: string) =>
    `${APPLICATIONS_ROUTES.application(applicationId)}/reviews?tab=suggestions`,
  applicationReviews: (applicationId: string) =>
    `${APPLICATIONS_ROUTES.application(applicationId)}/reviews`,
  applicationReview: (applicationId: string, reviewId: string) =>
    `${APPLICATIONS_ROUTES.applicationReviews(applicationId)}/${reviewId}`,
  applicationReviewOverview: (applicationId: string, reviewId: string) =>
    `${APPLICATIONS_ROUTES.applicationReviews(applicationId)}/${reviewId}?tab=overview`,
  applicationReviewAnalysis: (applicationId: string, reviewId: string) =>
    `${APPLICATIONS_ROUTES.applicationReviews(applicationId)}/${reviewId}?tab=analysis`,
  applicationReviewThreats: (applicationId: string, reviewId: string) =>
    `${APPLICATIONS_ROUTES.applicationReviews(applicationId)}/${reviewId}?tab=threats`,
  applicationReviewThreat: (applicationId: string, reviewId: string, threatId: string) =>
    `${APPLICATIONS_ROUTES.applicationReviews(applicationId)}/${reviewId}?tab=threats&threat=${threatId}`,
  applicationReviewRequirements: (applicationId: string, reviewId: string) =>
    `${APPLICATIONS_ROUTES.applicationReviews(applicationId)}/${reviewId}?tab=requirements`,
  applicationReviewRequirement: (applicationId: string, reviewId: string, requirementId: string) =>
    `${APPLICATIONS_ROUTES.applicationReviews(applicationId)}/${reviewId}?tab=requirements&requirement=${requirementId}`,
  applicationReviewImplementations: (applicationId: string, reviewId: string) =>
    `${APPLICATIONS_ROUTES.applicationReviews(applicationId)}/${reviewId}?tab=implementation`,
  applicationReviewImplementation: (
    applicationId: string,
    reviewId: string,
    implementationId: string
  ) =>
    `${APPLICATIONS_ROUTES.applicationReviews(applicationId)}/${reviewId}?tab=implementation&implementation=${implementationId}`,

  applicationThreatsPosture: (applicationId: string) =>
    `${APPLICATIONS_ROUTES.application(applicationId)}/threats-posture`,
};

// export const APPLICATIONS_QUERY_PARAMS = {
//   create: 'create',
// };
